















































import { Component, Vue } from "vue-property-decorator";

interface CentrumItem {
  title: string;
  subTitle?: string;
  paragraphs: string[];
  image: string;
  link?: string;
}

@Component
export default class OurCentrum extends Vue {
  mounted() {
    this.overlayLoading = false;
  }
  get overlayLoading() {
    return this.$store.getters.overlayLoading;
  }
  set overlayLoading(value) {
    this.$store.commit("setOverlayLoading", value);
  }
  ourCentrumItems: CentrumItem[] = [
    {
      title: "Witaj w Tenis Planet",
      subTitle:
        "tu gdzie łączymy sport z rodzinną atmosfera i pysznym smakiem!",
      paragraphs: [
        "Naszą misją jest, aby każdy czuł się jak u siebie w domu. Rodzina odgrywa niezwykle istotną rolę w życiu każdego człowieka. To wśród najbliższych nabywamy podstawową wiedzę i umiejętności niezbędne do funkcjonowania w rzeczywistości społecznej.",
        "Rodzice i krewni stają się przykładem dla dzieci, które, jak wiadomo nie od dziś, najszybciej uczą się poprzez obserwację i naśladownictwo dorosłych."
      ],
      image: "centrum1.jpg",
      link:
        "https://www.google.com/maps/place/Tenis+Planet/@51.0843931,16.9024075,3a,75y,200h,90t/data=!3m8!1e1!3m6!1sAF1QipPH3XVAa5qKD2k_xu4oAggtiH4eWjSwXgK4QOGE!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPH3XVAa5qKD2k_xu4oAggtiH4eWjSwXgK4QOGE%3Dw224-h298-k-no-pi-0-ya159.24-ro0-fo100!7i9460!8i4730!4m14!1m6!3m5!1s0x470fc0f9e919d151:0x48e8bf5522f9947f!2sPizza+Smolec!8m2!3d51.0841!4d16.9017899!3m6!1s0x470fc1cc75301bf3:0x9bb18effdd36944!8m2!3d51.0841951!4d16.9021956!14m1!1BCgIgARICCAI"
    },
    {
      title: "Tenis i inne sporty to nasza rodzinna pasja",
      paragraphs: [
        "Zapraszamy na nasz kameralny obiekt z rodzinną atmosferą. Nie chcemy się chwalić zawodnikami, trenerami. To są nieodłączne elementy każdego sportu. I każdy klub je ma. Bez nich by nie istniał.",
        "My chcemy się pochwalić... atmosferą. Obiekt prowadzimy całą rodziną, wspierani przez naszych współpracowników, którzy dla nas są także jak rodzina. Także i naszych klientów traktujemy jak rodzinę.",
        "Zapraszamy więc wszystkich z rodziny tenisowej na nasz obiekt. Miło nam będzie Was ugościć."
      ],
      image: "centrum2.jpg"
    },
    {
      title: "Sukcesy",
      paragraphs: [
        "Niezwykłe osiągnięcia oraz sukcesy sportowe bez wątpienia zależą w dużym stopniu od talentu oraz czasu jaki poświęcamy na treningi i ciągłe doskonalenie własnych umiejętności.",
        "Duże znaczenie także, wsparcie najbliższych. My chcemy Wam je dać."
      ],
      image: "centrum3.jpg"
    }
  ];
}
